import GamesModel, { FullGameData } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import { captureEvent } from '@/lib/util/captureEvent';
import { useRouter } from 'next/router';
import { Reducer, useReducer, useRef, useState } from 'react';



// #region Utility Functions

function tryParse <T> (data: string): T | undefined {
  let parsed: any;

  try {
    parsed = JSON.parse(data) as any;
  } catch (err) {
    return;
  }

  return parsed as T;
}

function tryParsePayload <T> (payload: MessagePayload): T | undefined {
  const parsed = tryParse<T>(payload.message);

  if (!parsed) {
    if (process.env.NODE_ENV == 'development') {
      // eslint-disable-next-line no-console
      console.warn('Invalid payload for event:', payload.eventName);
    }
    return;
  }

  return parsed;
}

// #endregion



// #region Toolbar

interface ToolbarItem {
  id: string;
  label?: string;
  icon?: string;
  disabled?: boolean;
  onClick?: boolean;
};

export interface ToolbarState {
  items: ToolbarItem[];
};

interface ToolbarStateAction {
  type: ToolbarStateActionType
  payload: any
};

enum ToolbarStateActionType {
  SET_ITEMS,
  ADD_OR_UPDATE_ITEM,
  REMOVE_ITEM,
};

function useToolbarState () {
  return useReducer<Reducer<ToolbarState, ToolbarStateAction>>((state, action) => {
    switch (action.type) {
    case ToolbarStateActionType.SET_ITEMS: {
      return {
        ...state,
        items: action.payload as ToolbarItem[],
      };
    }
    case ToolbarStateActionType.ADD_OR_UPDATE_ITEM: {
      return {
        ...state,
        items: (
          (!!state.items.find(item => item.id === action.payload.id))
            ? state.items.map(item => {
              if (item.id === action.payload.id) {
                return {
                  ...item,
                  ...(action.payload as ToolbarItem),
                };
              }
              return item;
            })
            : [
              ...state.items,
              action.payload as ToolbarItem,
            ]
        )
      };
    }
    case ToolbarStateActionType.REMOVE_ITEM: {
      return {
        ...state,
        items: state.items.filter(item => item.id !== (action.payload as string)),
      };
    }
    default: {
      return {
        ...state,
      };
    }
    }
  }, { items: [] });
}

// #endregion



// #region Message API

import { useEffect } from 'react';
import UAParser from 'ua-parser-js';

type MessageEventName = 
  'swag.toolbar.setItems' |
  'swag.toolbar.updateItem' |
  'swag.toolbar.removeItem' |
  'swag.toolbar.click' |
  'swag.toggleFullScreen' |
  'swag.navigateToArchive' |
  'swag.navigateToGameLanding' | 
  'swag.navigateToLogin' |
  'swag.navigateToTitle' | 
  'swag.displayAd' |
  'swag.displayShareDialog' |
  'swag.userLogout' |
  'swag.getRelatedGames' |
  'swag.captureEvent';

interface MessagePayload {
  eventName: MessageEventName;
  message: string;
}

interface UseMessageApiOptions {
  game: FullGameData
  targetWindowRef: React.RefObject<Window>
  onToggleFullScreen: () => boolean
  onDisplayAd: (type: 'video', options: {}) => void
  onDisplayShareDialog: () => void
  onNavigateToGameLanding?: () => void
}

const CAPTURE_EVENT_RATE_LIMIT = 5000;

export function useMessageApi (options: UseMessageApiOptions) {
  const router = useRouter();
  const [ authState ] = useAuthStore();
  const { logout } = useAuthMethods();

  const [ timePlayed, setTimePlayed ] = useState<number>(Date.now());
  const captureEventRateLimitRef = useRef<number>(0);

  {
    const lastGameIdRef = useRef<string>(options.game.uid);
    useEffect(() => {
      if (options.game.uid === lastGameIdRef.current) return;
      lastGameIdRef.current = options.game.uid;
      setTimePlayed(Date.now());
    }, [ options.game ]);
  }

  const sendMessage = (eventName: string, message: string = '') => {
    const el = document.getElementById('gameIframe') as HTMLIFrameElement;
    el.contentWindow.postMessage(
      JSON.stringify({ eventName, message }),
      '*',
    );
  };

  useEffect(() => {
    let messageHandler;

    window.addEventListener('message', messageHandler = async (event: { data: string }) => {
      // Validate the event
      if (
        !event || 
        typeof event.data !== 'string' || 
        !event.data.includes('eventName')
      ) return;

      // Try and parse the event data
      const parsed = tryParse<MessagePayload>(event.data);
      if (!parsed) return;

      // Execute events
      const { eventName } = parsed;

      switch (eventName) {
      case 'swag.toggleFullScreen': {
        sendMessage(`${eventName}.success`, JSON.stringify(options.onToggleFullScreen()));
        return;
      }
      case 'swag.navigateToArchive': {
        sendMessage(`${eventName}.success`);
        router.push(`${options.game.href}/archive`);
        return;
      }
      case 'swag.navigateToGameLanding': {
        sendMessage(`${eventName}.success`);

        if(typeof options?.onNavigateToGameLanding === 'function') {
          options.onNavigateToGameLanding();
        } else {
          router.push(`${options.game.href}`);
        }

        return;
      }
      case 'swag.navigateToLogin': {
        sendMessage(`${eventName}.success`);
        if (!authState.user) {
          router.push('/account/login');
        }
        return;
      }
      case 'swag.navigateToTitle': {
        if (!parsed.message) return sendMessage(`${eventName}.error`, 'Invalid payload.');

        sendMessage(`${eventName}.success`);
        router.push(`/gamelanding/${parsed.message}`);
        return;
      }
      case 'swag.displayAd': {
        const data = tryParsePayload<{ type: 'video', options: {} }>(parsed);
        if (!data) return sendMessage(`${eventName}.error`, 'Invalid payload.');

        if (authState.user && authState.user.isPremiumUser) {
          sendMessage(`${eventName}.success`);
          return;
        }

        sendMessage(`${eventName}.ack`);
        options.onDisplayAd(data.type, options);
        return;
      }
      case 'swag.displayShareDialog': {
        sendMessage(`${eventName}.ack`);
        options.onDisplayShareDialog();
        return;
      }
      case 'swag.userLogout': {
        sendMessage(`${eventName}.success`);
        logout();
        return;
      }
      case 'swag.getRelatedGames': {
        const parser = new UAParser(window.navigator.userAgent || '');
        const parserResults = parser.getResult();
        const isMobile = parserResults.device.type === 'mobile';

        const games = await GamesModel.getRelatedGames(options.game.uid, 3, 0, isMobile);
        const formatted = games.map(game => ({
          slug: game.href.split('/').pop(),
          title: game.title,
          icon: game.mobileIcon,
        }));
        sendMessage(`${eventName}.success`, JSON.stringify(formatted));
        return;
      }
      case 'swag.captureEvent': {
        const data = tryParsePayload<{ event: string, params: any }>(parsed);
        if (!data) return sendMessage(`${eventName}.error`, 'Invalid payload.');
        
        if (Date.now() < captureEventRateLimitRef.current + CAPTURE_EVENT_RATE_LIMIT) {
          return sendMessage(`${eventName}.error`, 'Rate limited exceeded.');
        }
        captureEventRateLimitRef.current = Date.now();

        try {
          const secondsPlayed = (Date.now() - timePlayed) / 1000;
          captureEvent((options.game.keyword || options.game.uid) + '__' + data.event, {
            ...data.params,
            id: options.game.uid,
            title: options.game.title,
            seconds_played: Math.round(secondsPlayed),
          });
          sendMessage(`${eventName}.success`);
        } catch (err) {
          sendMessage(`${eventName}.error`, 'Failed to capture event.');
        }
        return;
      }
      }
    });

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  });

  {
    const lastGameIdRef = useRef<string>(options.game.uid);
    useEffect(() => {
      if (lastGameIdRef.current === options.game.uid) return;
      lastGameIdRef.current = options.game.uid;
    }, [ options.game ]);
  }

  const sendShareDialogClosed = () => {
    sendMessage('swag.displayShareDialog.success');
  };

  const sendDisplayAdComplete = () => {
    sendMessage('swag.displayAd.success');
  };

  return {
    sendShareDialogClosed,
    sendDisplayAdComplete,
  };
}

interface UseMessageApiToolbarOptions {
  game: FullGameData
}

export function useMessageApiToolbar (options: UseMessageApiToolbarOptions) {
  const [ toolbarState, dispatchToolbarState ] = useToolbarState();

  {
    const lastGameIdRef = useRef<string>(options.game.uid);
    useEffect(() => {
      if (options.game.uid === lastGameIdRef.current) return;
      lastGameIdRef.current = options.game.uid;
    }, [ options.game ]);
  }

  const sendMessage = (eventName: string, message: string = '') => {
    const el = document.getElementById('gameIframe') as HTMLIFrameElement;
    el.contentWindow.postMessage(
      JSON.stringify({ eventName, message }),
      '*',
    );
  };

  useEffect(() => {
    let messageHandler;

    window.addEventListener('message', messageHandler = async (event: { data: string }) => {
      // Validate the event
      if (
        !event || 
        typeof event.data !== 'string' || 
        !event.data.includes('eventName')
      ) return;

      // Try and parse the event data
      const parsed = tryParse<MessagePayload>(event.data);
      if (!parsed) return;

      // Execute events
      const { eventName } = parsed;

      switch (eventName) {
      case 'swag.toolbar.setItems': {
        const data = tryParsePayload<ToolbarItem[]>(parsed);
        if (!data) return sendMessage(`${eventName}.error`, 'Invalid payload.');

        for (const item of data) {
          if (!item.id) return sendMessage(`${eventName}.error`, 'Invalid payload: Missing ID');
          if (!item.icon && !item.label) return sendMessage(`${eventName}.error`, 'Invalid payload: Missing icon or label');
        }

        const foundIds = [];
        for (const item of data) {
          if (foundIds.includes(item.id)) {
            return sendMessage(`${eventName}.error`, 'Invalid payload: Duplicate IDs');
          }
          foundIds.push(item.id);
        }

        sendMessage(`${eventName}.success`);
        dispatchToolbarState({
          type: ToolbarStateActionType.SET_ITEMS,
          payload: data,
        });
        return;
      }
      case 'swag.toolbar.updateItem': {
        const data = tryParsePayload<ToolbarItem>(parsed);
        if (!data) return sendMessage(`${eventName}.error`, 'Invalid payload.');
        if (!data.id) return sendMessage(`${eventName}.error`, 'Invalid payload: Missing ID');

        sendMessage(`${eventName}.success`);
        dispatchToolbarState({
          type: ToolbarStateActionType.ADD_OR_UPDATE_ITEM,
          payload: data,
        });
        return;
      }
      case 'swag.toolbar.removeItem': {
        if (!parsed.message) return sendMessage(`${eventName}.error`, 'Invalid payload.');

        sendMessage(`${eventName}.success`);
        dispatchToolbarState({
          type: ToolbarStateActionType.REMOVE_ITEM,
          payload: parsed.message,
        });
        return;
      }
      }
    });

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  });

  {
    const lastGameIdRef = useRef<string>(options.game.uid);
    useEffect(() => {
      if (lastGameIdRef.current === options.game.uid) return;
      lastGameIdRef.current = options.game.uid;

      dispatchToolbarState({
        type: ToolbarStateActionType.SET_ITEMS,
        payload: [],
      });
    }, [ options.game, dispatchToolbarState ]);
  }

  const sendToolbarClicked = (id: string) => {
    sendMessage('swag.toolbar.click', id);
  };

  return {
    toolbarState,
    sendToolbarClicked,
  };
}

// #endregion
